<template>
    <div class="mobile_container_top_margin main">
        <h1>FIFO Inventory Value</h1>
        <div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label>CDI Location</label>
                        <select class="form-control" ref="cdiLocationSelect"  v-model="cdiLocationID" >
                            <option value="0">Select a Location...</option>
                            <option v-for="[id, cdiLocation] in cache.cdiLocations" :key="id" :value="id">{{ cdiLocation }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4" v-if="cdiLocationID > 0 && dateOpts.length > 0">
                    <div class="form-group">
                        <label>Dates</label>
                        <select @change="getInventory" class="form-control" ref="dateSelect" v-model="selectedDate" >
                            <option value="0">None</option>
                            <option v-for="date in dateOpts" :key="date.fifo_inventory_date" :value="date" >{{ date.fifo_inventory_date }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row margin_bottom">
                <div class="col-sm-12">
                    <button class="btn btn-default" @click="reset">Reset Search</button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6" v-if="result && result.total && result.total.cumulative_total">
                <table class="table">
                    <thead>
                    <tr>
                        <th class="text-center">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <td class="text-center dashboard_lg_text">${{ financialFormat(result.total.cumulative_total) }}</td>
                    </tbody>
                </table>
            </div>
            <div class="col-sm-6">
                <table v-if="result && result.total && result.total.category_totals && Object.keys(result.total.category_totals).length > 0" class="table">
                    <thead>
                    <tr>
                        <th>Inventory Category</th>
                        <th class="number">Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="category in result.total.category_totals" :key="category.category">
                        <td>{{ category.category }}</td>
                        <td class="number">${{ financialFormat(category.value) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <table class="table table-condensed">
                    <thead>
                    <tr>
                        <th>Material</th>
                        <th>Date</th>
                        <th class="number">Quantity</th>
                        <th class="number">Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in result.materials" :key="line.fifo_inventory_date + line.raw_material_id" >
                        <td>{{ line.raw_material }}</td>
                        <td>{{ new Date(line.fifo_inventory_date).toLocaleDateString('en') }}</td>
                        <td class="number">{{ line.inventory_count }}</td>
                        <td class="number">${{ financialFormat(line.inventory_value) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import httpHelpers from "@/store/httpHelpers";
import cdiLocationCache from '@/cache/cdiLocation.cache';
import { store } from '@/store/Report.store'

export default {
    created() {
        this.$appStore.setTitle(['FIFO Inventory'])
        store.setReport('', {}, {}, {}, true, false);
        this.reset();
    },
    data() {
        return {
            cdiLocationID: 0,
            selectedDate: 0,
            result: {
                materials: {},
                total: {
                    category_totals: {},
                    cumulative_total: 0
                }
            },
            dateOpts: [],
            cache: {
                cdiLocations: cdiLocationCache.CDI_LOCATIONS
            }
        }
    },
    methods: {
        getInventory() {
            let scope = this;
            this.lockForm();
            httpHelpers.postRpt('fifo_value/get_result', {
                cdi_location_id: scope.cdiLocationID,
                month: scope.selectedDate.month,
                year: scope.selectedDate.year,
                day: scope.selectedDate.day
            }).then((data) => {
                scope.result = data;
            });
        },
        financialFormat(value) {
            if (value === null) return '';
            const formatted = Number(value).toFixed(2);
            return Number(formatted).toLocaleString('en');
        },
        reset: function() {
            this.dateOpts = [];
            this.cdiLocationID = 0;
            this.selectedDate = 0;
            this.unlockForm();
        },
        getDates: function() {
            let scope = this;
            httpHelpers.postRpt('fifo_value/get_inventory_dates', {
                cdi_location_id: scope.cdiLocationID
            }).then(function(res) {
                scope.dateOpts = res;
            });
        },
        lockForm: function() {
            this.$refs.cdiLocationSelect.setAttribute('disabled', 'true');
            this.$refs.dateSelect.setAttribute('disabled', 'true');
        },
        unlockForm: function() {
            this.$refs.cdiLocationSelect.removeAttribute('disabled');
            this.$refs.dateSelect.removeAttribute('disabled');
        }
    },
    watch: {
        cdiLocationID: function() {
            if (this.cdiLocationID > 0) {
                this.getDates();
            } else {
                this.selectedDate = 0
            }
        }
    }
}


</script>