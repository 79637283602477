import axios from 'axios';
import helpers from "@/store/helpers";

export default {
    post: function(endpoint, params) {
        let base_url = process.env.VUE_APP_URL + '/api/';
        return axios.post(base_url + endpoint, helpers.getQSString({params: params}), {headers: helpers.authHeader()})
            .then(function(response) {
                return response.data;
            })
            .catch(function(error){
                throw error;
            });
    },

    postRpt: function(endpoint, params) {
        let base_url = process.env.VUE_APP_URL + '/rapi/';
        return axios.post(base_url + endpoint, helpers.getQSString({params: params}), {headers: helpers.authHeader()})
            .then(function(response) {
                return response.data;
            })
            .catch(function(error){
                throw error;
            });
    }
};